import React from "react"
import * as styles from "../styles/index.module.css"

import { styled } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"

// import imgPuntazul from "../images/puntazul.png"
import imgCarrera from "../images/carrera.jpg"
import imgDespensas from "../images/despensas_covid.jpg"
import imgReconocimientos from "../images/reconocimiento.jpg"
import { Helmet } from "react-helmet"
// import Link from 'gatsby-link'
import Layout from "../components/layout"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const QuienesSomos = () => (
  <Layout>
    <Helmet>
      <title>Aries Online - Quienes somos</title>
    </Helmet>
    <section id="QuienesSomos">
      <Box sx={{ flexGrow: 1 }}>
        {/* {console.log(items)} */}
        {/* {items.map((item, index) => ( */}

        <Grid key={1} container className={styles.gridQS}>
          {/* {item.map(item => (
           */}
          <Grid key={"item-title"} item lg={10} md={10} sm={10} xs={11}>
            <h1 className={`${styles.titleQS} ${styles.titleMarginQS}`} style={{marginTop: "25px"}}>
              {/* Aries Fortalece tu Patrimonio cumple 20 años Fortaleciendo el
              Patrimonio de las Familias. ¡¡¡Enhorabuena!!! */}
              Aries Fortalece Tu Patrimonio{" "}
            </h1>
          </Grid>
          <Grid key={"item-1"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item1"} className={styles.gridItemQS}>
              <div className={styles.itemDivTextQS}>
                <p className={styles.subtitleLeftQS}>
                  {/* Somos una organización inmobiliaria líder */}
                  Somos una organización inmobiliaria líder{" "}
                </p>

                <p className={styles.textLeftQS}>
                  {/* Con un clara visión institucional de negocios con desarrollo
                  sostenible fundadores de un exitoso modelo de inversión
                  anticrisis en el 2002; para Fortalecer el Patrimonio de las
                  familias con la más alta plusvalía a través de la residencia
                  de sus sueños o un inmueble comercial en una privilegiada
                  ubicación con absoluta seguridad y certidumbre jurídica, pero
                  lo más importante; con grandes facilidades de pago sin
                  importar su historial crediticio según Forbes en su
                  publirreportaje el 05 de Agosto 2019. */}
                  Fundadores de un exitoso modelo de negocio de inversión para fortalecer el patrimonio de las familias con la más alta plusvalía a través de un bien inmueble en privilegiadas ubicaciones con absoluta seguridad y certidumbre jurídica, pero lo más importante; con grandes facilidades de pago.
                </p>
              </div>
              <div
                className={styles.itemDivImgQS}
                style={{
                  borderRadius: "5px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url("${imgCarrera}")`,
                }}
              >
                {/* <img  alt="Img" className={styles.imgQuienesSomos} key={"img"} src={imgPuntazul}/> */}
              </div>
            </Item>
          </Grid>
          <Grid key={"item-2"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item2"} className={styles.gridItemRightQS}>
              <div
                className={styles.itemDivImgQS}
                style={{
                  borderRadius: "5px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url("${imgDespensas}")`,
                }}
              >
                {/* <img  alt="Img" className={styles.imgQuienesSomos} key={"img2"} src={imgCarrera}/> */}
              </div>
              <div className={styles.itemDivTextQS}>
                {/* <p className={styles.subtitleRightQS}>Somos una organización inmobiliaria líder </p> */}
                <p className={styles.textRightQS} style={{ marginBlock: "4%" }}>
                  {/* Somos una Empresa Socialmente Responsable (ESR) por séptimo
                  año consecutivo, la primera y única empresa con el distintivo
                  de Socialmente Incluyente en el Estado de Baja California,
                  ocios con Voz y Voto del Consejo de Administración del Centro
                  Mexicano de la Filantropía, El Cemefi; la cuna de las Empresas
                  Socialmente Responsables. */}
                  Somos una Empresa Socialmente Responsable (ESR) por séptimo año consecutivo, la primera y única Empresa con el distintivo de Socialmente Incluyente en el Estado de Baja California y socios con voz y voto del Consejo de Administración del Centro Mexicano para la Filantropía, El Cemefi; la cuna de las Empresas Socialmente Responsables.  
                </p>
              </div>
            </Item>
          </Grid>
          <Grid key={"item-3"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item3"} className={styles.gridItemQS}>
              <div className={styles.itemDivTextQS}>
                <p className={styles.subtitleLeftQS}>
                  Nuestra Responsabilidad Social Empresarial
                </p>
                <p className={styles.textLeftQS}>
                  {/* Inicia fortaleciendo exitosamente el patrimonio de las
                  familias, y continua con el de nuestros colaboradores a través
                  de nuestra certificación Great Place To Work con un primer
                  lugar en 2021 en el sector inmobiliario y de la construcción
                  del ranking nacional, para de ahí contribuir con la
                  reconstrucción de nuestro tejido social apoyando al talento,
                  al deporte, al medio ambiente, la educación, el arte, la
                  cultura, a nuestros niños de escasos recursos y/o con
                  capacidades diferentes porque son el presente y futuro del
                  mundo; a esto es a lo que llamamos nuestro círculo virtuoso
                  Ganar-Ganar. */}
                  Inicia fortaleciendo el patrimonio de las familias, y continua con el de nuestros colaboradores a través de nuestra certificación Great Place To Work por cuarto año consecutivo figurando en el Ranking Nacional con el primer lugar en el sector inmobiliario y de la construcción en el 2021, para de ahí contribuir con la reconstrucción de nuestro tejido social apoyando al talento, al deporte, al medio ambiente, la educación, el arte, la cultura y nuestros niños de escasos recursos, con capacidades diferentes…  A esto es a lo que llamamos nuestro círculo virtuoso Ganar-Ganar.
                </p>
              </div>
              <div
                className={styles.itemDivImgQS}
                style={{
                  borderRadius: "5px",
                  backgroundSize: "cover",
                  backgroundPosition: "center top",
                  backgroundImage: `url("${imgReconocimientos}")`,
                }}
              >
                {/* <img  alt="Img" className={styles.imgQuienesSomos} key={"img3"} src={imgDespensas}/> */}
              </div>
            </Item>
          </Grid>
          <Grid key={"item-4"} item lg={10} md={10} sm={10} xs={11}>
            <Item key={"item4"} className={styles.gridItemRightQS}>
              <div
                className={styles.itemDivImgQS}
                style={{
                  borderRadius: "5px",
                  backgroundSize: "90%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundImage: `url("/17ObjetivosDeLaONU.jpg")`,
                }}
              >
                {/* <img  alt="Img" className={styles.imgQuienesSomos} key={"img4"} src={imgReconocimientos}/> */}
              </div>
              <div className={styles.itemDivTextQS}>
                <p className={styles.subtitleRightQS}>
                  En Grupo Aries ganamos todos
                </p>
                <p className={styles.textRightQS}>
                  {/* Porque somos orgullosamente una Empresa del Pacto Mundial
                  México de las Naciones Unidas y entusiastas promotores de los
                  17 Objetivos de Desarrollo Sostenible (ODS) de la Agenda 2030
                  de acuerdo con nuestra carta de emprendimiento firmada el 03
                  de Diciembre 2019 en Viena Austria para la ONU, ganadores del
                  Premio de Etica y Valores por la Confederación de las Cámaras
                  de la Industria de los Estados Unidos Mexicanos por tercer año
                  consecutivo; Premio Concamin, además de contar con
                  reconocimientos y acreditaciones de talla internacional en el
                  Estado de California, San Francisco CA, New York NY, Miami FL,
                  Sao Paulo Brasil, Paris Francia, Viena Austria figurando
                  dentro de ranking de las 1000 Empresas Mas Importantes en
                  nuestro México lindo y querido por la prestigiada industria
                  editorial Mundo Ejecutivo por quinto año consecutivamente. */}
                  Porque somos orgullosamente una Empresa del Pacto Mundial México de las Naciones Unidas y somos entusiastas promotores de los 17 Objetivos de Desarrollo Sostenible (ODS) de la Agenda 2030 de la ONU, ganadores del Premio de Etica y valores por la confederación de las cámaras de la industria de los Estados Unidos Mexicanos por tercer año consecutivo; Premio Concamin. Además de contar con reconocimientos y acreditaciones de talla internacional como en el Estado de california, San Francisco CA, Nueva York, Miami, Florida… En
                  Estados Unidos de America, Sao Paulo Brasil, Paris Francia, Viena Austria… Y en nuestro México Lindo y Querido.

                </p>
              </div>
            </Item>
          </Grid>
          <Grid key={"item-Despedida"} item lg={10} md={10} sm={10} xs={11}>
            <div className={styles.despedidaQS}>
              <p className={styles.textLeftQS}>Fraternalmente;</p>
              <p className={styles.textLeftQS}>Jorge Ojeda</p>
              <p className={styles.textLeftQS}>CEO Grupo Aries</p>
            </div>
          </Grid>
          {/* ))} */}
        </Grid>
        {/* ))} */}
      </Box>
    </section>
  </Layout>
)

export default QuienesSomos
